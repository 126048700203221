import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AxiosRequest, catchResponseError} from "../helpers/generalUtilities";
import axios from "axios";
import {Button, Checkbox, Col, Form, Input, Row, Typography} from 'antd';
import {RequireAuth, status} from "../helpers/auth";

const {Text} = Typography;


export function Login() {
    const navigate = useNavigate();

    const [error, setError] = useState("")

    useEffect(() => {
    })
    const login = () => {
        const options = {
            url: `${process.env.REACT_APP_API_BASE_URL}/admin/login`,
            method: 'post',
            data: {
                name: 'admin',
                password: '080897So!'
            }
        }

        axios.request(options)
            .then(response => {
                const data = response.data.data
                localStorage.setItem("authorization", data.token);
                localStorage.setItem("role", data.role)
                data.role === 'admin' ? navigate("/admin") : navigate("/waiter")
            })
            .catch(error => {
                if (error.response.status === 403)
                    alert("Wrong username or password")
                    console.log(error.response.status)
            });
    }

    const request = () => {
        // const options = {
        //     url: `blogs`,
        //     method: 'get',
        // }
        // AxiosRequest(options, response => {
        //     console.log(response)
        // })
    }

    const submitLogin = (values) => {
        setError("");
        const options = {
            url: `${process.env.REACT_APP_API_BASE_URL}/admin/login`,
            method: 'post',
            data: {
                name: values.name,
                password: values.password
            }
        }

        axios.request(options)
            .then(response => {
                const data = response.data.data
                localStorage.setItem("authorization", data.token);
                localStorage.setItem("role", status[data.role])
                localStorage.setItem("id",data.userId)
                status[data.role] == status.admin ? navigate("/admin") : navigate("/waiter")
            })
            .catch(error => {
                if (error.response.status === 403) {
                    // alert("Wrong username or password");
                    setError("Wrong username or password");
                }
                else(setError(error.response))
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row justify="center">
            <Col xs={{span: 20}} sm={{span: 20}} lg={{span: 16}}>
                <h1>login page</h1>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 10,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={submitLogin}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 10,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <Text type="danger">{error}</Text>
            </Col>
        </Row>
    )
}