import React from 'react';
import {Counter} from './features/counter/Counter';
import {Routes, Route, Link, Outlet} from "react-router-dom";
import logoHilltop from './Hilltop.png'
import logoPalace from './HalkidikiPalace.png'

import './App.css';
import {Col, Row} from 'antd';


function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Row justify="center">
                    <Col xs={{span: 20}}>
                        <img src={process.env.REACT_APP_HOTEL === 'hilltop' ? logoHilltop : logoPalace} alt="Logo" className="App-logo"/>
                    </Col>
                    <Col xs={{span: 20}}>
                        <h2>Order Management</h2>
                    </Col>
                </Row>

                <nav>
                    <Link to="/">Home</Link> {" | "}
                    <Link to="admin">Admin</Link>{" | "}
                    <Link to="waiter">Waiter</Link>{" | "}
                    <Link to="login">Login</Link>{" | "}
                </nav>
            </header>
            <Outlet/>
        </div>
    );
}

export default App;
