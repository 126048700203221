import {Button, Checkbox, Col, Form, Input, Row, notification, Select, InputNumber} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import {AxiosRequest} from "../../helpers/generalUtilities";
import {Option} from "antd/es/mentions";
import TextArea from "antd/es/input/TextArea";
import "./MenuItemForm.css"

const MenuItemForm = (props) => {
    const [saveAndContinue, setSaveAndContinue] = useState(false);

    const [form] = Form.useForm();
    useEffect(() => {
        if(props.item){
            form.setFieldsValue({
                item: props.item.item,
                menu_categories_id: props.item.menu_categories_id,
                description: props.item.description,
                price: props.item.price,
                no_print: props.item.no_print,
                select_all: props.item.select_all,
                properties: props.item.properties
            });
        } else {
            cleanForm()
        }
    },[props.item]);

    const cleanForm = () => {
        form.setFieldsValue({
            item: null,
            menu_categories_id: null,
            description: null,
            price: null,
            no_print: false,
            select_all: false,
            properties: []
        });
    }

    const onFinish = (values) => {
        const options = {
            url: props.item ? `item/${props.item.id}` : `item` ,
            method: 'post',
            data: values
        }
        AxiosRequest(options, response => {
            notification['success']({
                message: props.item ? 'Item Update successfully' : 'Item Create successfully',
            });
            if(saveAndContinue){
                cleanForm()
            } else {
                props.handleUpdate()
                cleanForm()
            }

        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Item"
                name="item"
                rules={[
                    {
                        required: true,
                        message: 'Please input the subcategory Name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="menu_categories_id"
                label="Category"
                rules={[
                    {
                        required: true,
                        message: 'Please select a category Name',
                    },
                ]}
            >
                <Select
                    allowClear
                    showSearch
                    filterOption={(inputValue, option) =>
                        option.children.toLowerCase().includes(inputValue.toLowerCase())
                    }
                >
                    {props.categories.map(category=> (
                        <Option key={category.id} value={category.id}>{category.category}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Price"
                name="price"
                rules={[
                    {
                        required: true,
                        message: 'Please input price',
                    },
                ]}
            >
                <InputNumber
                    formatter={(value) => `\u20AC ${value}`}
                    parser={(value) => value.replace(/\u20AC/g, '')}
                    step="0.1"
                />
            </Form.Item>
            <Form.Item
                label="Description"
                name="description"
                rules={[
                    {
                        message: 'Please input the description',
                    },
                ]}
            >
                <TextArea showCount maxLength={100} style={{ height: '100px' }}/>
            </Form.Item>
            <Form.Item
                label="No print"
                name="no_print"
                valuePropName="checked"
            >
                <Checkbox className={'checkbox-button'} >
                    No Print
                </Checkbox>
            </Form.Item>
            <Form.Item
                label="Exclude"
                name="select_all"
                valuePropName="checked"
            >
                <Checkbox className={'checkbox-button'} >
                    Exclude
                </Checkbox>
            </Form.Item>
            <Form.List
                name="properties"
            >
                {(properties, { add, remove }, { errors }) => (
                    <>
                        {properties.map((property, index) => (
                            <Form.Item
                                label={`Property ${index + 1}`}
                                required={false}
                                key={property.key}
                            >
                                <Form.Item
                                    {...property}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            whitespace: true,
                                            required:true,
                                            message: "Please input property or delete this field.",
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input
                                        placeholder="Please input property or delete this field."
                                        style={{
                                            width: '80%',
                                        }}
                                    />
                                </Form.Item>
                                <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(property.name)}
                                />
                            </Form.Item>
                        ))}
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}>
                            <Button
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                                block
                            >
                                Add Property
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Row justify="space-around">
                    <Col span={props.item ? 24 : 12}>
                        <Button type="primary" htmlType="submit" onClick={() => setSaveAndContinue(false)} block>
                            Save And Close
                        </Button>
                    </Col>
                    {!props.item &&
                        <Col span={12}>
                            <Button htmlType="submit" onClick={() => setSaveAndContinue(true)} block>
                                Save and Continue
                            </Button>
                        </Col>
                    }
                </Row>
            </Form.Item>
        </Form>
    );
};


export default MenuItemForm;

