import { SearchOutlined } from '@ant-design/icons';
import {Button, Col, Descriptions, Input, Popconfirm, Row, Space, Table} from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import {AxiosRequest} from "../../helpers/generalUtilities";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectItemsForOrder} from "../../features/itemsForOrder/orderSlice";

const OrderItems = props => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const itemsForOrder = useSelector(selectItemsForOrder);
    const searchInput = useRef(null);

    useEffect(() => {
        // console.log(props)
    },[props.orderItems]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters();
        setSearchText('');
        handleSearch('', confirm, dataIndex)
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
        dataIndex.split('.').reduce((a, b) => a[b], record)
            ?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (text),
    });

    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'item_id',
            width: '30%',
            ...getColumnSearchProps('itemName'),
            sorter: ((a, b) => {
                if(!(a.room_no)){
                    return 1
                }
                if(!(b.room_no)){
                    return -1
                }
                return a.room_no.toString().localeCompare(b.room_no.toString(), 'en-US',{numeric:"true"})
            })

        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '15%',
            ...getColumnSearchProps('quantity'),
            sorter: (a, b) => a.quantity.localeCompare(b.quantity, 'en-US', {numeric:"true"})

        },
        {
            title: 'Cost',
            dataIndex: 'price',
            key: 'price',
            width: '15%',
            sorter: (a, b) => a.price.localeCompare(b.price, 'en-US', {numeric:"true"}),
            render: (text) => <>{text}&#8364;</>
        },
        {
            title: 'Action',
            key: 'action',
            width: '15%',
            render: (_, record) => (
                <Row justify="start">
                    {record.status === 0 && !props.paid  &&
                        <Col span={12}>
                            <Button type="link" onClick={() => props.editItem(record)}>
                                Edit
                            </Button>
                        </Col>

                    }
                    {record.status === 1 &&
                        <Col span={12}>
                            <Button type="link" onClick={() => props.updateStatus(1,record)}>
                                Served
                            </Button>
                        </Col>
                    }
                    {!props.paid &&
                        <Col span={12}>
                            <Button type="link" onClick={() => props.duplicateItem(record)}>
                                Duplicate
                            </Button>
                        </Col>
                    }
                </Row>
            ),
        },
        {
            title: 'Time Stamp',
            dataIndex: 'createdAt',
            key: 'createdAt',
            // width: '20%',
            sorter: (a, b) => moment(a.createdAt)-moment(b.createdAt),
            render: (text) => moment(text).format('DD/MM/YYYY H:mm'),
        }
    ];


    return (
        <>
            <Table
                columns={columns}
                dataSource={props.orderItems}
                rowClassName={(record, index) => {
                    if(record.status === 0)
                        return 'item-new';
                    else if(record.status === 1)
                        return 'item-sent';
                    else
                        return 'item-served';
                }}
                rowKey="number"
                expandable={{
                    expandedRowRender: (record) => (
                        <>
                            <Descriptions style={{ textAlign: "left" }} labelStyle={{fontWeight: "bold"}} column={1}>
                                <Descriptions.Item label="Extra Description" >{record.extra_description}</Descriptions.Item>
                                <Descriptions.Item label="Properties" ><br/>
                                    <ul>
                                        {record.properties.map((property, i) => {
                                            return <li key={i}>{record.select_all ? `Without ${property}` : property}</li>
                                        })}
                                    </ul>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    ),
                }}
            />
        </>
    )
};

export default OrderItems