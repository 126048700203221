import {Button, Checkbox, Col, Form, Input, Row, notification, Space, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {AxiosRequest} from "../../helpers/generalUtilities";
import {Option} from "antd/es/mentions";

const MenuSubCategoryForm = (props) => {
    const [saveAndContinue, setSaveAndContinue] = useState(false);

    const [form] = Form.useForm();
    useEffect(() => {
        if(props.item){
            form.setFieldsValue({
                subCategory: props.item.category,
                categoryId: props.item.menu_category_parent.id
            });
        } else {
            cleanForm()
        }
    },[props.item]);

    const cleanForm = () => {
        form.setFieldsValue({
            subCategory: null,
            categoryId: null,
        });
    }

    const onFinish = (values) => {
        const options = {
            url: props.item ? `subCategory/${props.item.id}` : `subCategory` ,
            method: 'post',
            data: values
        }

        AxiosRequest(options, response => {
            notification['success']({
                message: props.item ? 'Subcategory Update successfully':'Subcategory Create successfully',
            });
            if(saveAndContinue){
                cleanForm()
            } else {
                props.handleUpdate()
            }

        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Subcategory Name"
                name="subCategory"
                rules={[
                    {
                        required: true,
                        message: 'Please input the subcategory Name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="categoryId"
                label="Category"
                rules={[
                    {
                        required: true,
                        message: 'Please select a category Name',
                    },
                ]}
            >
                <Select
                    allowClear
                    showSearch
                    filterOption={(inputValue, option) =>
                        option.children.toLowerCase().includes(inputValue.toLowerCase())
                    }
                >
                    {props.categories.map(category=> (
                        <Option key={category.id} value={category.id}>{category.category}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Row justify="space-around">
                    <Col span={props.item ? 24 : 12}>
                        <Button type="primary" htmlType="submit" onClick={() => setSaveAndContinue(false)} block>
                            Save And Close
                        </Button>
                    </Col>
                    {!props.item &&
                        <Col span={12}>
                            <Button htmlType="submit" onClick={() => setSaveAndContinue(true)} block>
                                Save and Continue
                            </Button>
                        </Col>
                    }
                </Row>
            </Form.Item>
        </Form>
    );
};

export default MenuSubCategoryForm;