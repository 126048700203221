import React, {useEffect, useState} from 'react';
import {Outlet, useOutlet, useNavigate, useLocation} from "react-router-dom";
import {  AppstoreOutlined, SettingOutlined, UsergroupAddOutlined  } from '@ant-design/icons';
import {Button, Menu} from "antd";
import "./admin.css"
import {AdminMainPage} from "./containers/admin/AdminMainPage";
import {WaiterMainPage} from "./containers/waiter/WaiterMainPage";
import {AxiosRequest} from "./helpers/generalUtilities";



export function Waiter() {
    const [current, setCurrent] = useState("");
    const location = useLocation();
    const navigate = useNavigate()
    const outlet = useOutlet()

    useEffect(() => {
        setCurrent(location.pathname)
    },[location])

    const onClick = (e) => {
        setCurrent(e.key);

        navigate(e.key)
    };

    const logout = () => {
        const options = {
            url: `logout`,
            method: 'post',
        }
        AxiosRequest(options, response => {
            localStorage.clear()
            window.location.reload();
        })
    }

    const onPanelChange = (value: Moment, mode: CalendarMode) => {
    };
    return (
        <div className="App">
            <Menu mode="horizontal"
                  defaultSelectedKeys={['main']}
                  onClick={onClick}
                  selectedKeys={[current]}
                  style={{ display: 'block' }}
            >
                <Menu.Item key="/waiter">
                    Main page
                </Menu.Item>
                <Menu.Item key="/waiter/new-order">
                    New Order
                </Menu.Item>
                <Menu.Item disabled={true} style={{ float: 'right' }}>
                    <Button onClick={logout}> LogOut</Button>
                </Menu.Item>
            </Menu>
            {outlet ? <Outlet/> :
                <>
                   <WaiterMainPage/>
                </>
            }
        </div>
    )
}
