import axios from 'axios';


var CancelToken = axios.CancelToken;
var CancelRequest;

const AxiosRequest = (options, responseCallback, errorResponseCallback=null) => {
    let headers = {};
    if (typeof options.headers === "undefined") {
        headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authorization')}`
            }
        }
    }
    const requestOptions = {
        ...options,
        baseURL: process.env.REACT_APP_API_BASE_URL,
        ...headers,
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            CancelRequest = c;
        })
    }
    if (requestOptions.url.indexOf('logout') !== -1) {
        delete requestOptions.cancelToken;
    }
    return axios.request(requestOptions)
        .then(response => responseCallback(response))
        .catch(
            errorResponseCallback ? errorResponseCallback: catchResponseError
        );
}

const catchResponseError = error => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);

        if (error.response.status === 403) {
            // clearToken();
            window.location.reload(true);
        } else {
            // error = DvIntlMessages('error.serverResponse') + " " +
            //     error.response.status + " " +
            //     error.response.data.message;
            console.log("error");
            // Message.error(DvIntlMessages('error.userServerResponse'));
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
        // Message.error(DvIntlMessages('error.clientRequest'));
    } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
    }

    //console.log(error.config);
}


export {catchResponseError, AxiosRequest, CancelRequest}