import {Checkbox, Col, Form, Row, Space, InputNumber, Typography, Button} from 'antd';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { selectItemsForOrder } from "../../features/itemsForOrder/orderSlice";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";

const { Paragraph } = Typography;

const NewOrderItemForm = React.forwardRef((props, ref) => {
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null)
    const itemsForOrder = useSelector(selectItemsForOrder);

    const [form] = Form.useForm();
    useEffect(() => {
        if (props.item) {
            drawButtons()
        } else {
        }
    }, [])
    useEffect(() => {
        if (itemsForOrder.length !== 0) {
            setCategories(itemsForOrder)
        } else {
        }
    }, [itemsForOrder]);


    useImperativeHandle(ref, () => ({
        getErrors() {
            return form.validateFields()
        },
        getItem() {
            return {
                is_update: props.item !== null && props.item.number !== null,
                number: props.item?.number,
                item_id: selectedItem.id,
                quantity: form.getFieldValue('quantity'),
                properties: form.getFieldValue('properties'),
                extra_description: form.getFieldValue('extra_description')
            }
        },
    }))

    const drawButtons = () => {
        // console.log(itemsForOrder);
        let find = false
        itemsForOrder.forEach(category => {
            if (find) { return }
            category.menu_categories_children.forEach(subCategory => {
                if (find) { return }

                subCategory.menu_categories_item.forEach(item => {
                    if (find) { return }
                    if (item.id == props.item.itemId) {
                        setSubCategories(category.menu_categories_children)
                        setItems(subCategory.menu_categories_item)

                        setSelectedItem(item)
                        setSelectedCategoryId(category.id);
                        setSelectedSubCategoryId(subCategory.id)

                        form.setFieldsValue({
                            quantity: props.item.quantity,
                            properties: props.item.properties,
                            extra_description: props.item.extra_description
                        });

                        find = true
                    }
                })

            })
            category.menu_categories_item.forEach(item => {
                if (find) { return }
                if (item.id == props.item.itemId) {
                    setItems(category.menu_categories_item)

                    setSelectedCategoryId(category.id)
                    setSelectedItem(item)

                    form.setFieldsValue({
                        quantity: props.item.quantity,
                        properties: props.item.properties,
                        extra_description: props.item.extra_description
                    });
                    find = true
                }
            })
        })
    }


    const handleCategoryChange = (e) => {

        const category = categories.find(({ id }) => id === parseInt(e.target.id));
        setSubCategories([])
        setItems([])
        setSelectedCategoryId(category.id)
        setSelectedSubCategoryId(null)
        setSelectedItem(null)
        if (category.menu_categories_children.length !== 0) {
            setSubCategories(category.menu_categories_children)
        } else if (category.menu_categories_item.length !== 0) {
            setItems(category.menu_categories_item)
        }
    }

    const handleSubCategoryChange = (e) => {

        const subCategory = subCategories.find(({ id }) => id === parseInt(e.target.id));
        setSelectedSubCategoryId(subCategory.id)
        setSelectedItem(null)

        setItems(subCategory.menu_categories_item)
    }
    const handleItemChange = (e) => {
        const item = items.find(({ id }) => id === parseInt(e.target.id));
        form.setFieldValue('quantity', 1)
        setSelectedItem(item)
    }

    const changeQuantity = (operator) => {
        let preValue = form.getFieldValue('quantity')
        form.setFieldValue('quantity',eval(`${preValue} ${operator}  1`))
        form.validateFields()
    }

    let spanItem = 24 - 8 - (subCategories.length ? 1 : 0) * 4
    return (
        <>
            <Row gutter={[8, 16]} >
                <Col span={4}>
                    <h3>Categories</h3>
                    <div className="scrollable-container">
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                            }}
                        >
                            {categories.map((item, index) => (
                                <button
                                    className={"new-order-item-button " + (item.id === selectedCategoryId ? "active-category-button" : "")}
                                    id={item.id}
                                    onClick={handleCategoryChange}>
                                    <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0px", pointerEvents: "none" }}>
                                        {item.category}
                                    </Paragraph>
                                </button>
                            ))}
                        </Space>
                    </div>
                </Col>
                {subCategories.length !== 0 &&
                    <Col span={4} >
                        <h3>Subcategories</h3>
                        <div className="scrollable-container">
                            <Space
                                direction="vertical"
                                style={{
                                    width: '100%',
                                }}
                            >
                                {subCategories.map((item, index) => (
                                    <button
                                        className={"new-order-item-button " + (item.id === selectedSubCategoryId ? "active-subcategory-button" : "")}
                                        id={item.id}
                                        onClick={handleSubCategoryChange}>
                                        <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0px", pointerEvents: "none" }}>
                                            {item.category}
                                        </Paragraph>
                                    </button>
                                ))}
                            </Space>
                        </div>
                    </Col>
                }
                {items.length !== 0 &&
                    <Col span={4} >
                        <h3>Items</h3>
                        <div className="scrollable-container">
                            <Space
                                direction="vertical"
                                style={{
                                    width: '100%',
                                }}
                            >
                                {items.map((item, index) => (
                                    <button
                                        className={"new-order-item-button " + (item.id === selectedItem?.id ? "active-item-button" : "")}
                                        id={item.id} onClick={handleItemChange}>
                                        <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0px", pointerEvents: "none" }}>
                                            {item.item}
                                        </Paragraph>
                                    </button>
                                ))}
                            </Space>
                        </div>
                    </Col>
                }
                {selectedItem &&
                    <Col span={spanItem}>
                        <Form name="Order Item"
                            form={form}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            initialValues={{ quantity: 1 }}
                            autoComplete="off"
                        >
                            <Form.Item label="Item Name" value={selectedItem.item}>
                                {selectedItem.item}
                            </Form.Item>
                            <Form.Item label="Price">
                                {selectedItem.price}&#8364;
                            </Form.Item>
                            <Form.Item
                                label={
                                    <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0px", pointerEvents: "none" }}>
                                        Description
                                    </Paragraph>
                                }>
                                {selectedItem.description}
                            </Form.Item>
                            <Form.Item
                                label="Quantity"
                                name="quantity"
                                type="number"
                                rules={
                                    [{
                                        required: true
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (parseInt(value)>=1 && parseInt(value)<=100) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(new Error("The quantity must be higher than 0"));
                                            }
                                        },
                                    })]
                            }
                            >
                                <InputNumber
                                    size="large"
                                    min={1}
                                    max={100}
                                    defaultValue={1}
                                    controls={false}
                                    addonBefore={<Button type="text" onClick={()=>changeQuantity('-')}>-</Button>}
                                    addonAfter={<Button type="text" onClick={()=>changeQuantity('+')}>+</Button>}
                                />
                            </Form.Item>
                            <Form.Item
                                name="properties"
                                label="Properties"
                            >
                                <Checkbox.Group>
                                    <Row align='strech' gutter={[8, 8]}>
                                        {selectedItem.properties.map(property => (
                                            <Col span={12} style={{ display: 'flex' }}>
                                                <Checkbox className={'checkbox-button'}
                                                    value={property}
                                                >
                                                    <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: "0px", pointerEvents: "none", wordBreak: "break-all" }}>
                                                        {selectedItem.select_all ? `Without ${property}` : property}
                                                    </Paragraph>
                                                </Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                                label={<span className='custom-form-item-label'>
                                    Extra description
                                </span>}
                                name="extra_description"
                                rules={[{ required: false }]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form>

                    </Col>
                }
            </Row>
        </>
    );
});


export default NewOrderItemForm;

