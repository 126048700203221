import {Button, Col, Form, Input, Row, notification, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import axios from "axios";

const {Text} = Typography;

const CreateWaiterForm = (props) => {

    const [errors, setErrors] = useState([])

    const [form] = Form.useForm();

    useEffect(() => {
        if(props.item){
            form.setFieldsValue({
                name: props.item.name,
            });
        } else {
            cleanForm()
        }
    });

    const cleanForm = () => {
        form.setFieldsValue({
            name: null,
            password: null,
            confirm_password: null
        });
    }

    const onFinish = (values) => {

        setErrors([]);
        const options = {
            url: `${process.env.REACT_APP_API_BASE_URL}/admin/waiter${props.item ? '/' + props.item.id :     ''}`,
            method: 'post',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authorization')}`
            },
            data: {
                name: values.name,
                password: values.password,
                confirm_password: values.confirm_password
            }
        }

        axios.request(options)
            .then(response => {
                notification['success']({
                    message: props.item ? 'User Update successfully' : 'User Create successfully',
                });
                props.handleUpdate()
                // status[data.role] == status.admin ? navigate("/admin") : navigate("/waiter")
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setErrors(Object.values(error.response.data.data));
                }
            });

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const errorList = errors.map((error) =>
        <li>
            {error[0]}
        </li>
    );


    return (
        <>
            <Form
                form={form}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="User Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the User name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm the password!',
                        },
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Row justify="space-around">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" block>
                                Save And Close
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
            <Text type="danger"><ul>{errorList}</ul></Text>
        </>
    );
};

export default CreateWaiterForm;