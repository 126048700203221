import {SearchOutlined} from '@ant-design/icons';
import {Button, Input, Modal, notification, Popconfirm, Space, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {AxiosRequest} from "../../../helpers/generalUtilities";
import MenuSubCategoryForm from "../../../components/admin/MenuSubCategoryForm";

const SubCategories = (props) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [subCategories, setSubCategories] = useState([])
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const searchInput = useRef(null);

    useEffect(() => {
    }, []);

    const showModal = (record) => {
        setSelectedSubCategory(record)
        setIsModalVisible(true);
    };

    const handleUpdate = () => {
        setIsModalVisible(false);
        props.getSubCategories();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        props.getSubCategories();
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const deleteCategory = (record) => {
        const options = {
            url: `category/${record['id']}`,
            method: 'delete',
        }

        AxiosRequest(options, response => {
            notification['success']({
                message: 'Category delete successfully',
            });
            props.getSubCategories()
        })
    }

    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters();
        setSearchText('');
        handleSearch('', confirm, dataIndex)
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            dataIndex.split('.').reduce((a, b) => a[b], record)
                .toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (text),
    });

    const columns = [{
        title: 'Sub Category',
        dataIndex: 'category',
        key: 'sub_category',
        width: '40%',
        ...getColumnSearchProps('category'),
        sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
        title: 'Category',
        dataIndex: ['menu_category_parent', 'category'],
        key: 'category',
        width: '40%',
        ...getColumnSearchProps('menu_category_parent.category'),
        sorter: (a, b) => a.menu_category_parent.category.localeCompare(b.menu_category_parent.category),
    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button type="link" onClick={() => showModal(record)}>Edit</Button>
                <Popconfirm
                    title={"Are you sure you want to continue"}
                    onConfirm={() => deleteCategory(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <a href="#">Delete</a>
                </Popconfirm>
            </Space>
        ),
    },];
    return (
        <>
            <Button type="primary" onClick={() => showModal(null)}>
                Add New
            </Button>
            <Table columns={columns} dataSource={props.subCategories}/>
            <Modal title="Subcategory"
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   footer={[]}
            >
                <MenuSubCategoryForm
                    item={selectedSubCategory}
                    handleUpdate={handleUpdate}
                    categories={props.categories}/>
            </Modal>
        </>
    )
};

export default SubCategories