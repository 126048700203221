// A mock function to mimic making an async request for data
import {AxiosRequest} from "../../helpers/generalUtilities";

export function fetchItemsForOrder() {
  return new Promise((resolve) => {
    const options = {
      url: `itemsForOrder`,
      method: 'get',
    }
    AxiosRequest(options, response => {
      resolve({data : response.data.data})
    })
  });
}

