import React, {useEffect, useState} from 'react';
import { Card } from 'antd';
import {Link} from "react-router-dom";
import Categories from "./Categories";
import SubCategories from "./SubCategories";
import Items from "./Items";
import {AxiosRequest} from "../../../helpers/generalUtilities";
import categories from "./Categories";

const tabListNoTitle = [
    {
        key: 'categories',
        tab: 'categories',
    },
    {
        key: 'subcategories',
        tab: 'subcategories',
    },
    {
        key: 'items',
        tab: 'items',
    },
];

const Menu = (props) => {
    const [activeTabKey, setActiveTabKey] = useState('categories');
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [availableCategories, setAvailableCategories] = useState()
    const [items, setItems] = useState([])

    useEffect(() => {
        fetchData();
    },[]);

    useEffect(() => {
        if(categories.length !== 0 && subCategories.length !== 0)
            createAvailableCategories()
    },[categories,subCategories]);

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    const fetchData = () => {
        getCategories();
        getSubCategories();
        getItems();
    }

    const getCategories = () => {
        const options = {
            url: `categories`,
            method: 'get',
        }
        AxiosRequest(options, response => {
            setCategories(response.data.data)
        })
    }

    const getSubCategories = () => {
        const options = {
            url: `subCategories`,
            method: 'get',
        }
        AxiosRequest(options, response => {
            setSubCategories(response.data.data)
        })
    }

    const getItems = () => {
        const options = {
            url: `items`,
            method: 'get',
        }
        AxiosRequest(options, response => {
            setItems(response.data.data)
        })
    }

    const createAvailableCategories = () => {
        let availableCategories = [];
        categories.forEach((category) => {
            availableCategories.push({
                id: category.id,
                category: `${category.category} (category)`
            })
        })
        subCategories.forEach((subCategory) => {
            availableCategories.push({
                id: subCategory.id,
                category: `${subCategory.category} (subcategory)`
            })
        })

        setAvailableCategories(availableCategories)
    }
    
    const contentListNoTitle = {
        categories: <Categories
            key={"categories"}
            categories={categories}
            getCategories={getCategories}/>,
        subcategories: <SubCategories
            key={"subCategories"}
            subCategories={subCategories}
            categories={categories}
            getSubCategories={getSubCategories}/>,
        items: <Items
            key={"item"}
            items={items}
            availableCategories={availableCategories}
            getItems={getItems}
        />,
    };

    return (
        <>
            <h3>Menu-Managment</h3>
            <Card
                style={{
                    width: '100%',
                }}
                tabList={tabListNoTitle}
                activeTabKey={activeTabKey}
                tabBarExtraContent={<Link to="/admin">Admin</Link>}
                onTabChange={(key) => {
                    onTabChange(key);
                }}
            >
                {contentListNoTitle[activeTabKey]}
            </Card>
        </>

    )
}

export default Menu