import React, {useEffect, useRef, useState} from 'react';
import {Col, Descriptions, Divider, Row} from "antd";

export const PrintOrderComponent = React.forwardRef((props, ref) => {
    const [newItem, setNewItem] = useState([])
    const [oldItem, setOldItem] = useState([])
    useEffect(()=> {
    })
    useEffect(() => {
        let newI = []
        let oldI = []

        props.order?.items?.forEach(function(item) {
            if(item.status===0 ){
                if(item.no_print!==1)
                    newI.push(item);
            } else {
                oldI.push(item);
            }
        })
        setNewItem(newI)
        setOldItem(oldI)

    },[props.order.items]);

    const formatDateTime = () => {
        let date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
    return (
        <div ref={ref}>
            <div className={"print-info"}>
                <Row>
                    <Col span={12}><b>Waiter:</b> {props.order.waiterName}</Col>
                    <Col span={12}><b>Order No:</b> {props.order.orderId}</Col>
                    <Col span={12}><b>Room No:</b> {props.order.roomNo ? props.order.roomNo : "-" }</Col>
                    <Col span={12}><b>Table No:</b> {props.order.tableNo ? props.order.tableNo : "-" }</Col>
                    <Col span={14}><b>Time:</b> {formatDateTime()}</Col>
                    <Col span={14}><b>Description:</b> {props.order.orderDescription ? props.order.orderDescription : "-" }</Col>
                </Row>
            </div>

            {!props.printAll && <>
                <Divider className={"print-divider"} dashed={true}>New Items</Divider>
                {newItem.map((item, i) => {
                    // if(item.no_print !=1)
                        return <div className="print-item">
                            <h2>{item.quantity} X {item.itemName}</h2>
                            <h4>{item.extra_description}</h4>
                            <ul>
                                {item.properties.map((property, i) => {
                                    return <li key={i}>{item.select_all ? `Without ${property}` : property}</li>
                                })}
                            </ul>
                            <Divider className={"print-divider"} dashed={true}></Divider>

                        </div>
                    })}
            </>}
            {props.printAll && <>
                <Divider className={"print-divider"} dashed={true}>Items</Divider>
                {props.order?.items?.map((item, i) => {
                    return <div className="print-item">
                        <Row>
                            <Col span={16}><h2>{item.quantity} X {item.itemName} </h2></Col>
                            <Col span={8}><div className={"print-sum"} >{item.quantity} X {item.price} = {item.quantity*item.price}&#8364;</div></Col>
                        </Row>
                        <h4>{item.extra_description}</h4>
                        <ul>
                            {item.properties.map((property, i) => {
                                return <li key={i}>{item.select_all ? `Without ${property}` : property}</li>
                            })}
                        </ul>
                        <Divider className={"print-divider"} dashed={true}></Divider>
                    </div>
                })}
                <Row>
                    <Col span={16}><h2>Total</h2></Col>
                    <Col span={8}><h2 style={{textAlign:"right"}} >{props.order.bill}&#8364;</h2></Col>
                </Row>
            </>
            }
        </div>
    )
});

