import React, {useEffect, useState} from 'react';
import {Col, Descriptions, Row} from "antd";
import {AxiosRequest} from "../../helpers/generalUtilities";

const WaiterSum = (props) => {
    const [waiters, setWaiters] = useState([])

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = () => {
        getSum();
    }

    const getSum = () => {
        const options = {
            url: `admin/waiterSum`,
            method: 'get',
        }
        AxiosRequest(options, response => {
            setWaiters(response.data.data)
        })
    }

    return (
        <>
            <h1>Waiter Sum</h1>
            <Row>
                {waiters.map(function(waiter, i){
                    return <>
                        <Col xs={24} sm={12} md={8} style={{paddingBottom:"8px"}}>
                            <Descriptions  style={{ textAlign: "left", marginBottom: "0px!important" }} labelStyle={{fontWeight: "bold", marginBottom: "0px"}} column={1} size={"small"}>
                                <span style={{fontWeight: "bold", textDecoration: "underline"}}>{waiter.name}</span>
                                <Descriptions.Item label="closed" style={{paddingBottom: "0px"}}>{waiter.sums.closed}&#8364;</Descriptions.Item>
                                <Descriptions.Item label="open" style={{paddingBottom: "0px"}}>{waiter.sums.open}&#8364;</Descriptions.Item>

                                <Descriptions.Item label="cash" style={{paddingBottom: "0px"}}>{waiter.sums.cash}&#8364;</Descriptions.Item>
                                <Descriptions.Item label="card" style={{paddingBottom: "0px"}}>{waiter.sums.card}&#8364;</Descriptions.Item>
                                <Descriptions.Item label="pms" style={{paddingBottom: "0px"}}>{waiter.sums.pms}&#8364;</Descriptions.Item>
                            </Descriptions>
                        </Col>

                    </>
                })}
            </Row>
        </>
    )
}
export default WaiterSum