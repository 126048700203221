import React, {useEffect, useState} from 'react';
import {Button, Col, Descriptions, Input, Row, Space} from "antd";
import Orders from "../../components/admin/Orders";
import {useNavigate} from "react-router-dom";
import {AxiosRequest} from "../../helpers/generalUtilities";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ArrowRightOutlined} from '@ant-design/icons';

const {Search} = Input;

export function AdminMainPage(props) {
    const navigate = useNavigate();

    const [roomOrTable, setRoomOrTable] = useState('')
    const [sum, setSum] = useState([])

    const [date, setDate] = useState(null);

    useEffect(() => {
        setToday()
    },[props.secret])


    useEffect(() => {
        if (date != null) {
            getSum()
        }
    }, [date])

    const createOrder = () => {
        navigate("new-order")
    }

    const getSum = () => {
        let startDate = null
        let endDate = null
        if (date) {
            startDate = date[0].toString().substr(0,15)
            endDate = date[1].toString().substr(0,15)
        }

        const options = {
            url: `admin/sum`,
            params: {
                all: props.secret ? 1 : 0,
                startDate: startDate,
                endDate: endDate
            },
            method: 'get',
        }
        AxiosRequest(options, response => {
            setSum(response.data.data)
        })
    }

    const setToday = () => {
        let tempDay = new Date()
        setDate([tempDay, tempDay])
    }

    const redirectToOrder = (value) => {
        window.open(`/admin/order?id=${value}`,'_blank')
    }
    return (
        <>
            <Row>
                <Col span={5} style={{borderRight: "1px solid"}}>
                    {props.secret && <>
                        <Calendar
                            selectRange
                            returnValue={"range"}
                            value={date}
                            style={{width: "100%"}}
                            onChange={setDate}
                            className="calendar-card"
                        />
                        <Button type={"primary"} onClick={setToday} block>Today</Button>
                    </>
                    }
                    <div>
                        <Space direction="vertical" style={{margin: '10px'}}>
                            <Search
                                placeholder="ID / Room / Table Number"
                                onChange={event => setRoomOrTable(event.target.value)}
                            />
                            <Button type={"button"} className="order-button" onClick={createOrder}>Create order</Button>
                            <Descriptions style={{textAlign: "left"}} labelStyle={{fontWeight: "bold"}} column={1}>
                                <Descriptions.Item label="Open">{sum.open}&#8364;</Descriptions.Item>
                                <Descriptions.Item label="Paid">{sum.closed}&#8364;</Descriptions.Item>
                                {props.secret &&
                                    <Descriptions.Item label="All">{sum.all}&#8364;</Descriptions.Item>
                                }
                            </Descriptions>
                        </Space>
                    </div>
                    <Search addonBefore="https://" placeholder="ID"  enterButton={<ArrowRightOutlined />} onSearch={redirectToOrder}/>

                </Col>
                <Col span={19}>
                    <Orders roomOrTable={roomOrTable} secret={props.secret} date={date} waiter={false}/>
                </Col>

            </Row>
        </>

    )
}
