import {SearchOutlined} from '@ant-design/icons';
import {Button, Input, notification, Popconfirm, Space, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AxiosRequest} from "../../helpers/generalUtilities";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import {fetchItemsForOrderAsync, selectItemsForOrder} from "../../features/itemsForOrder/orderSlice";

const Orders = props => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [orders, setOrders] = useState([])
    const [filteredOrders, setFilteredOrders] = useState([])

    const dispatch = useDispatch();
    const itemsForOrder = useSelector(selectItemsForOrder);

    const searchInput = useRef(null);

    useEffect(() => {
        if (itemsForOrder.length === 0) {
            dispatch(fetchItemsForOrderAsync())
        }
    }, []);

    useEffect(() => {
        if (props.date != null) {
            fetchData();
        }
    }, [props.date])

    useEffect(() => {
        if (props.roomOrTable === '') {
            setFilteredOrders(orders)
        } else {
            let result;
            if(props.waiter) {
                result = orders.filter(order => (
                    order.room_no?.toString().toLowerCase().includes(props.roomOrTable.toLowerCase())
                    || order.table_no?.toLowerCase().includes(props.roomOrTable.toLowerCase())
                ));
            }
            else {
                result = orders.filter(order => (
                    order.room_no?.toString().toLowerCase().includes(props.roomOrTable.toLowerCase())
                    || order.table_no?.toLowerCase().includes(props.roomOrTable.toLowerCase())
                    || order.id?.toString().toLocaleLowerCase().includes(props.roomOrTable.toLowerCase())
                ));
            }
            setFilteredOrders(result)
        }
    }, [props.roomOrTable, orders])

    const fetchData = () => {
        let startDate = null
        let endDate = null
        if (props.date) {
            startDate = props.date[0].toString().substr(0,15)
            endDate = props.date[1].toString().substr(0,15)

        }
        const options = {
            url: `orders`,
            params: {
                all: props.secret ? 1 : 0,
                startDate: startDate,
                endDate: endDate
            },
            method: 'get',
        }
        AxiosRequest(options, response => {
            setOrders(response.data.data)
        })
    }

    const payOrder = (record, closedMethod) => {
        const options = {
            url: `order/hasUnsentItems/${record.id}`,
            method: 'get',
            data: {
                "closed_method": closedMethod
            }
        }
        AxiosRequest(options, response => {
            if (response.data.data === false) {
                const options = {
                    url: `order/pay?order_id=${record.id}`,
                    method: 'put',
                    data: {
                        "closed_method": closedMethod
                    }
                }
                AxiosRequest(options, response => {
                    fetchData();
                })
            } else {
                notification['error']({
                    message: 'You must send all the item before paid',
                });
            }
        })
    }

    const copyToPms = (record) => {
        const options = {
            url: `order/hasUnsentItems/${record.id}`,
            method: 'get',
        }
        AxiosRequest(options, response => {
            if (response.data.data === false) {
                const options = {
                    url: `order/copyToPms?order_id=${record.id}`,
                    method: 'put',
                }
                AxiosRequest(options, response => {
                        notification['success']({
                            message: 'Order close successfully',
                        });
                        fetchData()
                    },
                    error => {
                        console.log("custom error:", error.response.data.message)
                        notification['error']({
                            message: error.response.data.message
                        });
                    })
            } else {
                notification['error']({
                    message: 'You must send all the item before paid',
                });
            }
        })
    }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters();
        setSearchText('');
        handleSearch('', confirm, dataIndex)
    };
    const deleteOrder = (id) => {
        const options = {
            url: `order/delete?order_id=${id}`,
            method: 'delete',
        }
        AxiosRequest(options, response => {
            notification['success']({
                message: 'Order delete successfully',
            });
            fetchData()
        })
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            dataIndex.split('.').reduce((a, b) => a[b], record)
                ?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (text),
    });

    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'id',
            key: 'id',
            hidden: props.waiter,
            width: '10%',
            ...getColumnSearchProps('id')
        },
        {
            title: 'Room NO',
            dataIndex: 'room_no',
            key: 'room_no',
            width: '10%',
            ...getColumnSearchProps('room_no'),
            sorter: ((a, b) => {
                if (!(a.room_no)) {
                    return 1
                }
                if (!(b.room_no)) {
                    return -1
                }
                return a.room_no.toString().localeCompare(b.room_no.toString(), 'en-US', {numeric: "true"})
            })

        },
        {
            title: 'Table NO',
            dataIndex: 'table_no',
            key: 'table_no',
            width: '10%',
            ...getColumnSearchProps('table_no'),
            sorter: ((a, b) => {
                if (!(a.table_no)) {
                    return 1
                }
                if (!(b.table_no)) {
                    return -1
                }
                return a.table_no.localeCompare(b.table_no, 'en-US', {numeric: "true"})
            })

        },
        {
            title: 'Orders',
            dataIndex: 'order_items_count',
            key: 'order_items_count',
            hidden: !props.waiter,
            width: '10%',
        },
        {
            title: 'Cost',
            dataIndex: 'bill',
            key: 'bill',
            width: '10%',
            sorter: (a, b) => a.bill.localeCompare(b.bill, 'en-US', {numeric: "true"}),
            render: (text) => <>{text}&#8364;</>
        },
        {
            title: 'Time Stamp',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '20%',
            sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
            render: (text) => moment(text).format('DD/MM/YYYY H:mm'),
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`${localStorage.getItem('role') === '1' ? '/admin' : '/waiter'}/order?id=${record.id}`}
                          className="btn btn-primary">
                        {record.paid ? 'See' : 'Edit'}
                    </Link>
                    {!props.waiter &&
                        <Popconfirm
                        title={"Are you sure you want to DELETE this order"}
                        onConfirm={()  => deleteOrder(record.id)}
                        okText="Yes"
                        cancelText="No"
                        >
                            <Button type="link" >Delete</Button>
                        </Popconfirm>}
                </Space>
            ),
        },
        {
            title: 'Waiter',
            dataIndex: ['user', 'name'],
            key: 'name',
            width: '15%',
            hidden: props.waiter,
            ...getColumnSearchProps('user.name'),
            sorter: (a, b) => a.user.name.localeCompare(b.user.name),
        },
        {
            title: 'Pay',
            key: 'action',
            render: (_, record) => (
                <>
                    {!record.paid &&
                        <Space size="middle">
                            <Popconfirm
                                title={"Are you sure you want to continue"}
                                onConfirm={() => payOrder(record, 0)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary">Cash</Button>
                            </Popconfirm>
                            <Popconfirm
                                title={"Are you sure you want to continue"}
                                onConfirm={() => payOrder(record, 1)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary">Card</Button>
                            </Popconfirm>
                            <Popconfirm
                                title={"Are you sure you want to continue"}
                                onConfirm={() => copyToPms(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>PMS</Button>
                            </Popconfirm>
                        </Space>
                    }
                    {record.paid==1  && closeMethod(record.closed_method)}
                </>
            ),
        },
    ].filter(item => !item.hidden);
    const closeMethod = (closed_method) => {
        switch(closed_method) {
            case 0:
                return <>Cash</>
            case 1:
                return <>Card</>
            case 2:
                return <>PMS</>
        }

    }
    return (
        <>
            <Table
                columns={columns}
                dataSource={filteredOrders}
                rowKey="id"
            />
        </>
    )
};

export default Orders