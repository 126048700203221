import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Descriptions, Modal, notification, Popconfirm, Row} from "antd";
import {AxiosRequest} from "../../helpers/generalUtilities";
import {useNavigate, useSearchParams} from "react-router-dom";
import "./order.css"
import OrderItems from "../../components/order/OrderItems";
import NewOrderItemForm from "../../components/order/NewOrderItemForm";
import {useDispatch, useSelector} from "react-redux";
import {fetchItemsForOrderAsync, selectItemsForOrder} from "../../features/itemsForOrder/orderSlice";
import {useReactToPrint} from 'react-to-print';
import {PrintOrderComponent} from "../../components/order/PrintOrderComponent";
import TextArea from "antd/es/input/TextArea";


export function WaiterOrder(props) {
    const navigate = useNavigate();

    const [order, setOrder] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalWidth, setModalWidth] = useState('100vw');
    const [printAll, setPrintAll] = useState(null);
    const [modalCommentVisible, setModalCommentVisible] = useState(false)
    const [comment, setComment] = useState("")

    const dispatch = useDispatch();
    const itemsForOrder = useSelector(selectItemsForOrder);

    const childRef = useRef();
    const componentRef = useRef();

    useEffect(() => {
        searchParams.get("id")
        fetchData()
        if (itemsForOrder.length === 0) {
            dispatch(fetchItemsForOrderAsync())
        }
    }, [])

    useEffect(() => {
        if (printAll !== null)
            handlePrint()
    }, [printAll])

    useEffect(() => {
        if(order.comment)
            setComment(order.comment)
    },[order])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 1200) {
                setModalWidth('80vw');
            } else {
                setModalWidth('100vw');
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchData = () => {
        const options = {
            url: `order?order_id=${searchParams.get("id")}`,
            method: 'get',
        }
        AxiosRequest(options, response => {
            setOrder(response.data.data)
        })
    }
    const editItem = (item) => {
        showModal(item)
    }

    const duplicateItem = (item) => {
        item.number = null
        showModal(item)
    }

    const showModal = (record) => {
        setSelectedItem(record)
        setIsModalVisible(true);
    };

    const handleUpdate = () => {
        const item = childRef.current.getItem()
        const options = {
            url: `order/addItem`,
            method: 'put',
            data: {
                order_id: order.orderId,
                item: item
            }
        }
        AxiosRequest(options, response => {
            notification['success']({
                message: 'Item added successfully'
            });
            fetchData()
            handleCancel()
        })
    };

    const updateStatus = (existingStatus, record = null) => {
        let data = {
            order_id: order.orderId,
            status: existingStatus,
        }
        if (record) {
            data.number = record.number
        }
        const options = {
            url: `order/updateStatus`,
            method: 'put',
            data: data
        }
        AxiosRequest(options, response => {
            notification['success']({
                message: 'Items updated successfully'
            });
            setOrder(response.data.data)
        })
        if (existingStatus === 0) {
            let hasNewItemsToPrint = (() => {
                for (let item of order.items) {
                    if (item.no_print != 1 && item.status == 0) {
                        return true
                    }
                }
                return false
            })();
            if (hasNewItemsToPrint) {
                setPrintAll(false)
            }
        }
    }

    const payOrder = (closedMethod) => {
        if ((order.items.findIndex((item) => item.status === 0) === -1)) {
            const options = {
                url: `order/pay?order_id=${order.orderId}`,
                method: 'put',
                data: {
                    "closed_method": closedMethod
                }
            }
            AxiosRequest(options, response => {
                notification['success']({
                    message: 'Order close successfully',
                });
                fetchData()
            })
        } else {
            notification['error']({
                message: 'You must send all the item before paid',
            });
        }
    }
    const printAllItems = () => {
        if (printAll === true)
            handlePrint();
        else
            setPrintAll(true)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setPrintAll(null)
    });

    const handleCancel = () => {
        setIsModalVisible(false);
        props.getItems()
    };

    const closeComment = () => {
        setModalCommentVisible(false);
    }

    const addComment = () => {
        const options = {
            url: `order/comment`,
            method: 'put',
            data: {
                "order_id": order.orderId,
                "comment": comment
            }
        }
        AxiosRequest(options, response => {
            notification['success']({
                message: 'Your comment add successfully',
            });
            closeComment()
        })
    }

    const renderButtons = () => {
        if (order.paid) {
            return <>
                <Row>
                    <Col span={24}>
                        <Button className="order-button waiter-paid-button" onClick={printAllItems}>Print</Button>
                    </Col>
                    <Col span={24}>
                        <Button className="order-button waiter-paid-button" onClick={() => setModalCommentVisible(true)}>Comment</Button>
                    </Col>
                </Row>
            </>
        } else {
            return <>
                <Row>
                    <Col span={8}>
                        <Button className="order-button item-new" onClick={() => showModal(null)}>Add New Item</Button>
                    </Col>
                    <Col span={8}>
                        <Button className="order-button item-sent" onClick={() => updateStatus(0)}>Send New
                            Items</Button>
                    </Col>
                    <Col span={8}>
                        <Button className="order-button item-served" onClick={() => updateStatus(1)}>Served Sent
                            Items</Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Popconfirm
                            title={"Are you sure you want to continue"}
                            onConfirm={() => payOrder(0)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button className="order-button waiter-button">Cash</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={8}>
                        <Popconfirm
                            title={"Are you sure you want to continue"}
                            onConfirm={() => payOrder(1)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button className="order-button waiter-button">Card</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={8}>
                        <Button className="order-button waiter-button" onClick={printAllItems}>Print</Button>
                    </Col>
                </Row>
            </>
        }
    }
    return (
        <>
            <Row justify={'space-between'}>
                <Col span={24} style={{borderRight: "1px solid"}}>
                    <Descriptions style={{textAlign: "left"}} labelStyle={{fontWeight: "bold"}} column={3}>
                        <Descriptions.Item label="OrderItems No">{order.orderId}</Descriptions.Item>
                        <Descriptions.Item label="Room No">{order.roomNo ? order.roomNo : "-"}</Descriptions.Item>
                        <Descriptions.Item label="Table No">{order.tableNo ? order.tableNo : "-"}</Descriptions.Item>
                        <Descriptions.Item label="Bill">{order.bill}&#8364;</Descriptions.Item>
                        <Descriptions.Item
                            label="Description">{order.orderDescription ? order.orderDescription : "-"}</Descriptions.Item>
                        <Descriptions.Item
                            label="Comment">{order.comment ? order.comment : "-"}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            {renderButtons()}
            <Col span={24}>
                <OrderItems
                    paid={order.paid}
                    orderItems={order.items}
                    editItem={editItem}
                    duplicateItem={duplicateItem}
                    updateStatus={updateStatus}
                />
            </Col>

            <Modal title="Item"
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   onOk={handleUpdate}
                   width={modalWidth}
                   destroyOnClose={true}
            >
                <NewOrderItemForm ref={childRef} item={selectedItem}/>
            </Modal>
            <Modal title="Write the comment here"
                   visible={modalCommentVisible}
                   onCancel={closeComment}
                   onOk={addComment}
                   destroyOnClose={true}
            >
                <TextArea rows={4} value={comment} onChange={(e)=> setComment(e.target.value)}/>
            </Modal>
            <div style={{display: 'none'}}>
                <PrintOrderComponent
                    ref={componentRef}
                    order={order}
                    printAll={printAll}
                />
            </div>
        </>

    )
}
