import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Typography} from "antd";
import "./order.css"
import TextArea from "antd/es/input/TextArea";
import {AxiosRequest} from "../../helpers/generalUtilities";
import {useNavigate} from "react-router-dom";

const {Text} = Typography;

export function NewOrder(props) {
    const [errors, setErrors] = useState([])
    const navigate = useNavigate();

    const [form] = Form.useForm();

    useEffect(() => {

    }, [])
    const onFinish = (values) => {
        const options = {
            url: `order/create`,
            method: 'post',
            data: {
                description: values.description,
                room_no: values.room_no,
                table_no: values.table_no,
                users_id: localStorage.getItem('id')
            }
        }

        AxiosRequest(options, response => {
            if (response.status === 200) {
                navigate(`/${localStorage.getItem('role') == 1 ? 'admin' : 'waiter'}/order?id=${response.data.data.id}`)

            }
        })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const errorList = errors.map((error) =>
        <li>
            {error[0]}
        </li>
    );
    return (
        <>
            <h1>New order</h1>

            <Form
                form={form}
                name="basic"
                justify="start"
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{maxWidth: 600}}

            >
                <Form.Item
                    label="Room No"
                    name="room_no"
                    rules={[
                        {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: 'Room No must be a number'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                let table_no = getFieldValue('table_no')
                                if (value || table_no) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(new Error());
                                }
                            },
                        })
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Table No"
                    name="table_no"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                let room_no = getFieldValue('room_no')
                                if (value || room_no) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(new Error('Room NO or Table NO must be filled'));
                                }
                            },
                        })
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <TextArea showCount maxLength={100} style={{height: '100px'}}/>
                </Form.Item>
                <Form.Item
                    wrapperCol={{span: 14, offset: 4}}
                >
                    <Row justify="space-around">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" block>
                                Save And Close
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
            <Text type="danger">
                <ul>{errorList}</ul>
            </Text>

        </>

    )
}
