import {Button, Checkbox, Col, Form, Input, Row, notification, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {AxiosRequest} from "../../helpers/generalUtilities";

const MenuCategoryForm = (props) => {

    const [saveAndContinue, setSaveAndContinue] = useState(false);

    const [form] = Form.useForm();
    useEffect(() => {
        if(props.item){
            form.setFieldsValue({
                category: props.item.category,
            });
        } else {
            cleanForm()
        }
    },[props.item]);

    const cleanForm = () => {
        form.setFieldsValue({
            category: null,
        });
    }

    const onFinish = (values) => {
        const options = {
            url: props.item ? `category/${props.item.id}` : `category` ,
            method: 'post',
            data: values
        }

        AxiosRequest(options, response => {
            notification['success']({
                message: props.item ? 'Category Update successfully':'Category Create successfully',
            });
            if(saveAndContinue){
                cleanForm()
            } else {
                props.handleUpdate()
            }

        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Category Name"
                name="category"
                rules={[
                    {
                        required: true,
                        message: 'Please input the Category Name',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Row justify="space-around">
                    <Col span={props.item ? 24 : 12}>
                        <Button type="primary" htmlType="submit" onClick={() => setSaveAndContinue(false)} block>
                            Save And Close
                        </Button>
                    </Col>
                    {!props.item &&
                        <Col span={12}>
                            <Button htmlType="submit" onClick={() => setSaveAndContinue(true)} block>
                                Save and Continue
                            </Button>
                        </Col>
                    }
                </Row>
            </Form.Item>
        </Form>
    );
};

export default MenuCategoryForm;