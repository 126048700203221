import React, {useEffect, useState} from 'react';
import {Outlet, useOutlet, useNavigate, useLocation} from "react-router-dom";
import {  AppstoreOutlined, SettingOutlined, UsergroupAddOutlined  } from '@ant-design/icons';
import {Button, Menu} from "antd";
import "./admin.css"
import {AdminMainPage} from "./containers/admin/AdminMainPage";
import {AxiosRequest} from "./helpers/generalUtilities";

export function Admin(props) {
    const [current, setCurrent] = useState("");
    const location = useLocation();
    const navigate = useNavigate()
    const outlet = useOutlet()

    useEffect(() => {
        setCurrent(location.pathname)
    },[location])

    const onClick = (e) => {
        setCurrent(e.key);

        navigate(e.key)
    };
    const logout = () => {
        const options = {
            url: `logout`,
            method: 'post',
        }
        AxiosRequest(options, response => {
            localStorage.clear()
            window.location.reload();
        })
    }

    return (
        <div className="App">
            <Menu mode="horizontal"
                  defaultSelectedKeys={['main']}
                  onClick={onClick}
                  selectedKeys={[current]}
                  style={{ display: 'block' }}
            >
                <Menu.Item key="/admin">
                    Admin page
                </Menu.Item>
                <Menu.Item key="/admin/new-order">
                    New Order
                </Menu.Item>
                <Menu.Item key="/admin/waiter-sum">
                    Waiter Sum
                </Menu.Item>
                <Menu.SubMenu key="SubMenu" title="Settings" icon={<SettingOutlined />}>
                    <Menu.Item key="/admin/menu-management" icon={<AppstoreOutlined />}>
                        Menu Management
                    </Menu.Item>
                    <Menu.Item key="/admin/users-management" icon={<UsergroupAddOutlined  />}>
                        Users Management
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item disabled={true} style={{ float: 'right' }}>
                    <Button onClick={logout}> LogOut</Button>
                </Menu.Item>
            </Menu>
            {outlet ? <Outlet/> :
                <>
                   <AdminMainPage secret={props.secret}/>
                </>
            }
        </div>
    )
}
