import * as React from "react";
import { Navigate } from "react-router-dom";

const status = {
    logout : 0,
    admin : 1,
    waiter : 2
}

function getAuthStatus() {

    const token = localStorage.getItem("authorization");
    const role = localStorage.getItem("role")

    if(token && role ) {
        return role
    }
    else{
        return status.logout
    }
}

const RequireAuth = (props,{ children }) => {
    const authStatus = getAuthStatus()
    if (authStatus == status.logout || authStatus != props.role ) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" replace />;
    }

    return props.children;
}

export {RequireAuth, getAuthStatus, status}



