import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import orderReducer from '../features/itemsForOrder/orderSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    authorization: null,
    itemsForOrder: orderReducer
  },
});
