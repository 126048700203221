import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'antd/dist/antd.min.css';
import PublicRouter from "./routers/PublicRouter";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <PublicRouter/>
            </BrowserRouter>
        </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
