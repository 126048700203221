import React, {useEffect, useState} from 'react';
import {Col, Row, Input, Space, Button} from "antd";
import Orders from "../../components/admin/Orders";
import {useNavigate} from "react-router-dom";

const { Search } = Input;

export function WaiterMainPage(props) {
    const navigate = useNavigate();

    const [roomOrTable, setRoomOrTable] = useState('')
    const [date, setDate] = useState(null);

    useEffect(() => {
        setToday()
    },[])


    const createOrder = () => {
        navigate("new-order")
        // this.props.history.push('/new-order');
    }

    const setToday = () => {
        let tempDay = new Date()
        setDate([tempDay, tempDay])
    }

    return (
            <>
                <Row>
                    <Col span={24} >
                        <div>
                            <Space direction="vertical"
                                   style={{
                                       width: '100%',
                                       margin: '20px auto'
                                   }}
                                   size={"large"}
                                   block={true}>
                                <Search
                                    placeholder="Room / Table Number"
                                    onChange={event => setRoomOrTable(event.target.value)}
                                />
                            </Space>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Orders roomOrTable={roomOrTable} date={date}  waiter={true}/>
                    </Col>

                </Row>
            </>

    )
}
