import React from 'react';
import {Routes, Route, Link, Outlet} from "react-router-dom";
import {Admin} from "../Admin";
import MenuManagement from "../containers/admin/MenuManagement/Menu";
import UserManagement from "../containers/admin/UserManagement/Users";
import App from "../App";
import {Login} from "../auth/Login";
import {Waiter} from "../Waiter";
import {RequireAuth, status} from "../helpers/auth";
import {Order} from "../containers/order/Order";
import {WaiterMainPage} from "../containers/waiter/WaiterMainPage";
import {NewOrder} from "../containers/order/NewOrder";
import {WaiterOrder} from "../containers/order/WaiterOrder";
import WaiterSum from "../containers/admin/WaiterSum";

const PublicRouter = () => {

    return (
        <Routes>
            <Route path="/" element={<App/>}>
                <Route path="login" element={<Login/>}/>
            </Route>
            <Route path="admin" element={
                <RequireAuth role={status.admin}>
                    <Admin/>
                </RequireAuth>
            }>
                <Route path="menu-management" element={<MenuManagement/>}/>
                <Route path="users-management" element={<UserManagement/>}/>
                <Route path="order" element={<Order/>}/>
                <Route path="new-order" element={<NewOrder/>}/>
                <Route path="waiter-sum" element={<WaiterSum/>}/>
            </Route>
            <Route path="admin/all" element={
                <RequireAuth role={status.admin}>
                    <Admin secret={true}/>
                </RequireAuth>
            }>
            </Route>
            <Route path="waiter" element={
                <RequireAuth role={status.waiter}>
                    <Waiter/>
                </RequireAuth>
            }>
                <Route path="new-order" element={<NewOrder/>}/>
                <Route path="order" element={<WaiterOrder/>}/>
                {/*<NewOrder></NewOrder>*/}
            </Route>
        </Routes>

    )
}



export default PublicRouter